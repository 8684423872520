html, body {
    display: flex;
    flex: 1;
    margin: auto;
}

body {
    background-color: #252525;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    background-color: #252525;
    color: #8c8c8c;
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 2vmin;
    margin-bottom: 1vmin;
}

#root {
    width: 100%;
    height: 100%;
}

.site-pagination {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    border-radius: 4px;
    margin: 2px;
}

.site-pagination > li {
    display: flex;
    width: 100%;
}

.site-pagination > li > a,
.site-pagination > li > span {
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    border: 1px solid #301B3F;
    border-radius: 4px;
    background-color: #3C415C;
    color: #B4A5A5;
    margin: 2px;
}

.site-pagination > li > a:hover, .site-pagination > li > span:hover {
    color: #B4A5A5;
    background-color: #301B3F;
}

.site-pagination > .active > a, .site-pagination > .active > a:focus, .site-pagination > .active > a:hover, .site-pagination > .active > span, .site-pagination > .active > span:focus, .site-pagination > .active > span:hover {
    color: #B4A5A5;
    cursor: default;
    background-color: #3C415C;
    border-color: #B4A5A5;
}
